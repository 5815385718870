<template>
    <div class="aplayz_mo_space_modal" :class="isactive">
        <div v-if="isactive === 'active'" class="background_dim"></div>
        <div class="aplayz_mo_space_modal_header">
            <h3 class="mo_space_header_title">마이스페이스</h3>
            <img src="/media/images/icon_close_24.svg" alt="icon_close_24" @click="$emit('close-myspace')" />
        </div>
        <ul class="mo_space_modal_body">
            <template v-if="channelList.length > 0">
                <li class="mo_space_modal_item cursor" v-for="(item, index) in spaceList" :key="'mochannel' + index" @click="clickJoin(item)">
                    <img v-if="item" class="mo_space_icon" :class="{ active: item.channelId === $store.getters.gettersChannelId }" :src="item.spaceImg" />
                    <p class="mo_spact_name" v-if="item">{{ item.spaceNm }}</p>
                    <div v-if="item && isPlay && item.spaceId === getSpaceId">
                        <equaliaer-comp />
                    </div>
                </li>
            </template>
        </ul>
    </div>
</template>
<script>
import { subscribeSpaceList } from '@/service/api/profileApi';
export default {
  name: 'MySpaceMobile',
  emits: ['close-myspace', 'newcreate-channel'],
  props: {
    isactive: {
      type: String,
      requried: false,
      default: ''
    }
  },

  components: {
    'equaliaer-comp': () => import(`@/components/player/Equalizer.vue`)
  },
  data () {
    return {
      spaceList: [],
      baseImgUrl: 'https://www.aplayz.co.kr/media/upload'
    };
  },
  created () {
    const userId = this.$cookies.get('userId');
    subscribeSpaceList(userId)
      .then((res) => {
        if (res.data.resultCd === '0000') {
          const list = res.data.result;
          list.forEach((item) => {
            let src = '/media/img/car.png';
            if (item.brandImg != null) {
              src = `${this.baseImgUrl}${item.brandImg.substr(12)}`;
            } else if (item.spaceType !== 'Car') {
              if (item.sector === '카페') {
                src = '/media/img/cafe.png';
              } else if (item.sector === '음식점') {
                src = '/media/img/food.png';
              } else if (item.sector === '주점') {
                src = '/media/img/drink.png';
              } else if (item.sector === '병원') {
                src = '/media/img/hospital.png';
              } else if (item.sector === '편의시설') {
                src = '/media/img/Facilities.png';
              } else if (item.sector === '숙박시설') {
                src = '/media/img/thumnail_hotel.png';
              } else if (item.sector === '기타') {
                src = '/media/img/Group 485982.png';
              } else if (item.sector == null) {
                src = '/media/img/Group 485982.png';
              }
            }
            item.src = src;
          });
          // for (const i in list) {
          // if (list[i].brandImg != null && list[i].brandImg !== undefined) {
          // list[i].brandImg = list[i].brandImg.substring(12);
          // list[i].imgUrl = list[i].brandImg;
          // } else {
          /// /

          // }
          // }
          this.spaceList = list;
        } else {
          this.spaceList = [];
        }
      })
      .catch((err) => {
        this.spaceList = [];
        console.error('error : ', err);
      });
  },
  mounted () {},

  methods: {
    clickJoin (item) {
      this.$emit('newcreate-channel', item);
      // this.$store.dispatch('player/joinChannel', item);
    }
  },
  computed: {
    channelList () {
      return this.$store.getters['player/getMobileChannelList'];
    },

    isPlay () {
      return this.$store.getters.gettersIsPlay;
    },
    getSpaceId () {
      return this.$store.getters['player/getMyChannelInfo'] ? this.$store.getters['player/getMyChannelInfo'].spaceId : '';
    }
  }
};
</script>
<style scoped src="@/assets/css/player/mobiletemplate.css"></style>
